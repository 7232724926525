<template>
  <div>
    <div class="box-card">
      <!-- <div style="margin-bottom: 10px"><i class="card-line"></i>报告列表</div> -->
      <!-- 外汇table切换暂时不用 -->

      <div v-if="[1, 2, 3, 5, 4].indexOf(courseType) === -1">
        <el-radio-group v-model="financeRadio" :disabled="disabled" size="small" @change="getDrafrList"
          style="margin:10px -10px;" text-color="#3B6EFB" fill="#FFFF">
          <el-radio-button label="证券" value="证券" />
          <el-radio-button label="外汇" value="外汇" />
        </el-radio-group>
      </div>
      <div>
        <div style="margin-bottom: 20px;float: left;"><i class="card-line"></i>报告列表</div>
        <div v-for="(item, index) in reportList" :key="item.key" @click="tabBtn(item.router)" style="float: right;">
          <el-button v-if="item.name != '报告列表'" size="mini" :type="item.name == '新建' ? 'warning' : 'primary'"
            style="margin-right: 10px;"> {{ item.name }} </el-button></div>
      </div>
      <div class="content-main" style="clear: both;">
        <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize"
          :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange"
          @clickButton="clickButton" :tableColumn="startList" :tableData="spreadTable"></spread-table>
      </div>
      <el-dialog v-model="showHtml" width="80%" :close-on-click-modal="false">
        <div class="title">{{ showHtmlTitle }}</div>
        <div style="height:50vh; overflow: auto;color: #03032C;" v-html="reportHtml">
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import WangEditor from '@/components/WangEnduitTry/index.vue';

import { startData } from "./startData";
import { clickButtons, currentChanges, sizeChanges, getDrafrLists } from "./startMethods";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    WangEditor,
  },
  setup() {
    let router = useRouter();
    const route = useRoute();
    onMounted(() => {
      if (sessionStorage.getItem('reportList')) {
        data.reportList = JSON.parse(sessionStorage.getItem('reportList'))
      }
      data.courseType = JSON.parse(sessionStorage.getItem('courseType'))
      if (route.query.financeRadio) {
        data.financeRadio = route.query.financeRadio
      }
      getDrafrList()
    })
    let data = reactive(startData)
    let getDrafrList = () => { getDrafrLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => {
      data.showHtmlTitle = val.item.name;
      clickButtons(val, data, router)
    }
    const tabBtn = (url) => {
      router.push(url)
    }
    return {
      ...toRefs(data),
      getDrafrList,
      sizeChange,
      currentChange,
      clickButton,
      tabBtn
    }
  }
})
</script>

<style scoped lang="less">
.content-main {
  height: calc(100vh - 405px);
  overflow: auto;
}

.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}

::v-deep.el-radio-button {
  .el-radio-button__inner {
    background: #FFFF;
    color: #333;
    border: 0 !important;
    font-size: 16px !important;
  }
}

:deep(.el-dialog__close.el-icon-close) {
  &::before {
    content: "\e78d";
    font-size: 18px;
  }
}

:deep(.el-dialog__body) {
  padding-top: 0px;
}

.title {
  text-align: center;
  font-size: 24px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #03032C;
  line-height: 31px;
  margin-bottom: 20px;
}
</style>